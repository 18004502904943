@tailwind base;
@tailwind components;

@layer components {
  .btn-large {
    @apply block items-center rounded-md border border-gray-700 bg-white px-6 py-3 text-center text-base font-medium text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-button focus:ring-offset-2 sm:inline-flex;
  }
  .body-link {
    @apply font-medium underline decoration-sky-600 decoration-1 underline-offset-2;
  }
  .body-section-header {
    @apply text-xl font-semibold uppercase tracking-wider text-brand-text;
  }
}

@tailwind utilities;

#__next {
  min-height: 100%;
}
